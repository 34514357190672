import { combineReducers } from 'redux';
import manageCustomer from '../../components/signup/reduxStore/reducer';
import AccountSetup from '../../components/account-setup/reduxStore/reducer';
import allUserApplicationsDetails from '../../components/login/reduxStore/reducer';
import ResetPassword from '../../components/reset-password/reduxStore/reducer';
import WrapperComponents from '../../components/Wrappers/reduxStore/reducer';
import ReferralReducer from '../../components/refer-a-friend/redux/reducer';
import BusinessAccountQuiz from '../../components/test-your-account-quiz/reduxStore/reducer';
import Reducer from '../../components/login-v2/redux-store/reducer';
import MyProfileReducer from '../../components/my-profile/redux-store/reducer';
import SimplifyLoginReducer from '../../components/simplify-login-verify-otp-component/reduxStore/reducer';
import dashboardV2Reducer from '../../components/dashboard/redux-store/reducer';

const appReducer = combineReducers({
  customerInfo: manageCustomer,
  AccountSetupInfo: AccountSetup,
  allUserApplicationsDetails: allUserApplicationsDetails,
  ResetPassword: ResetPassword,
  WrapperComponents: WrapperComponents,
  referralReducer: ReferralReducer,
  businessAccountQuiz: BusinessAccountQuiz,
  loginSsoUser: Reducer,
  userProfileInfo: MyProfileReducer,
  SimplifyLoginReducer: SimplifyLoginReducer,
  dashboardV2Reducer: dashboardV2Reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT' || action.type === 'RESET_REDUX_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
