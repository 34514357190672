import * as actionTypes from './actionTypes';

let initialState = {
  generateResetPasswordOtp: {},
  loading: false,
  fetchSsoLoading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GENERATE_RESET_PASSWORD_OTP_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_RESET_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        generateResetPasswordOtp: { ...action.generateResetPasswordOtp },
        loading: false,
        error: false
      };

    case actionTypes.VERIFY_RESET_PASSWORD_OTP_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_RESET_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        verifySsoEmailForResetPasswordResponse: { ...action.verifySsoEmailForResetPasswordResponse },
        loading: false,
        error: false
      };

    case actionTypes.VERIFY_SSO_RESET_PASSWORD_OTP_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_SSO_RESET_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_SSO_RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        verifySsoResetPasswordOtpResponse: { ...action.verifySsoResetPasswordOtpResponse },
        loading: false,
        error: false
      };

    case actionTypes.RESET_SSO_PASSWORD_ACTION_START:
      return { ...state, loading: true };
    case actionTypes.RESET_SSO_PASSWORD_ACTION_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.RESET_SSO_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        resetSsoPasswordResponse: { ...action.resetSsoPasswordResponse },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
