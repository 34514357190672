import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

customizedDialogs.propTypes = {
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.any,
  minWidth: PropTypes.any,
  isCloseRequired: PropTypes.bool,
  nameWidgetModalStyle: PropTypes.bool
};

customizedDialogs.defaultProps = {
  title: '',
  isCloseRequired: true,
  titleVariant: 'h6',
  nameWidgetModalStyle: false
};

const styles = (theme) => ({
  root: {
    margin: 0,
    zIndex: 99999
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, titleVariant, isCloseRequired, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant={titleVariant}>{children}</Typography>
      {onClose ? (
        <>
          {isCloseRequired && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </>
      ) : null}
    </MuiDialogTitle>
  );
});

function customizedDialogs({
  children,
  openDynamicModal,
  closeDynamicModal,
  maxWidth,
  minWidth,
  title,
  titleVariant,
  isCloseRequired,
  nameWidgetModalStyle
}) {
  const useStyles = makeStyles({
    modalStyle: {
      '& .MuiPaper-rounded': {
        borderRadius: '16px',
        zIndex: 10000
      },
      '& .MuiTypography-h5': {
        fontWeight: 700,
        lineHeight: '36px',
        letterSpacing: '-0.5px',
        fontFamily: 'HKGrotesk-Regular'
      }
    }
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(openDynamicModal);
  const handleClose = () => {
    setOpen(false);
    closeDynamicModal();
  };
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: '0px 32px 32px 32px',
      maxWidth: maxWidth,
      minWidth: minWidth,
      [theme.breakpoints.down('md')]: {
        minWidth: '100%',
        padding: nameWidgetModalStyle && '0px 8px 16px 8px'
      },
      zIndex: 10000
    }
  }))(MuiDialogContent);
  const Dialog = withStyles((theme) => ({
    root: {
      justifyContent: 'end',
      zIndex: `10000 !important`,
      '& .MuiDialogTitle-root': {
        [theme.breakpoints.down('md')]: {
          padding: nameWidgetModalStyle && '16px 8px'
        }
      },
      '& .MuiDialog-paper': {
        [theme.breakpoints.down('md')]: {
          margin: nameWidgetModalStyle && '12px'
        },
        zIndex: 10000
      }
    }
  }))(MuiDialog);
  useEffect(() => {
    setOpen(openDynamicModal);
  }, [openDynamicModal]);
  return (
    <Dialog
      className={nameWidgetModalStyle ? classes.modalStyle : ''}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        titleVariant={titleVariant}
        isCloseRequired={isCloseRequired}
        onClose={handleClose}
      >
        <div className={nameWidgetModalStyle ? 'md:ml-2 ml-0' : 'ml-2'}>{title}</div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default customizedDialogs;
